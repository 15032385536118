import React , { useEffect , useState } from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import Testimonail from "../components/testimonial";
import virtual_icon1 from "../img/virtual-mirror/Eyeglasss2.png";
import virtual_icon2 from "../img/virtual-mirror/Eyeglasss3.png";
import virtual_icon3 from "../img/virtual-mirror/Eyeglasss1M.png";
import virtual_icon4 from "../img/virtual-mirror/Eyeglasss4.png";
import virtual_icon5 from "../img/virtual-mirror/Eyeglasss5M.png";
import virtual_try1 from "../img/virtual-mirror/Eyeglasss2_2.png";
import virtual_try2 from "../img/virtual-mirror/Eyeglasss3_3.png";
import virtual_try3 from "../img/virtual-mirror/Eyeglasss1_1.png";
import virtual_try4 from "../img/virtual-mirror/Eyeglasss4_4.png";
import virtual_try5 from "../img/virtual-mirror/Eyeglasss5_5.png";
//AOS
import AOS from "aos";
import "aos/dist/aos.css";

const imgArray = [
  {
    img_icon : virtual_icon1,
    img_try : virtual_try1
  },
  {
    img_icon : virtual_icon2,
    img_try : virtual_try2
  },
  {
    img_icon : virtual_icon3,
    img_try : virtual_try3
  },
  {
    img_icon : virtual_icon4,
    img_try : virtual_try4
  },
  {
    img_icon : virtual_icon5,
    img_try : virtual_try5
  },
  {
    img_icon : virtual_icon4,
    img_try : virtual_try4
  },
  {
    img_icon : virtual_icon3,
    img_try : virtual_try3
  },
  {
    img_icon : virtual_icon2,
    img_try : virtual_try2
  },
  {
    img_icon : virtual_icon1,
    img_try : virtual_try1
  }
]


var timer, isRight=true, isLeft=false;

export default function VirutalMirror() {
  const [mvlimit , setmvlimit] = useState(0);
  const [moveRight , setMoveRight] = useState(1);
  useEffect(() => { AOS.init({}); }, []);


  const moveCarouselRight = () => {
    if(moveRight < 8){
      setmvlimit(mvlimit => mvlimit - 11);
      setMoveRight(moveRight => moveRight + 1);
    }
  }

  const moveCarouselLeft = () => {
    if(moveRight > 0){
      setmvlimit(mvlimit => mvlimit + 11);
      setMoveRight(moveRight => moveRight - 1);
    }
  }

  useEffect(() => {
    let container = document.querySelector(".virtualCarousel");
    container.style.transform = `translateX(${mvlimit}%)`;
  }, [mvlimit])

  useEffect(() => {
    let virtualpreview = document.getElementById("virtualpreview");
    virtualpreview.setAttribute("src", imgArray[moveRight].img_try);
  },[moveRight])


  useEffect(() => {
    if(moveRight === 0 || isRight){
      timer = setInterval(() => {moveCarouselRight();},5000);
      isRight = true;
      isLeft = !isRight;
    }
    if(moveRight === 8 || isLeft){
      timer = setInterval(() => {moveCarouselLeft();},5000)
      isLeft = true;
      isRight = !isLeft;
    }
    console.log(moveRight)
    return () => {
      clearInterval(timer);
    }
  })



  return (
    <div>
      <Helmet>
        <title>OIC Virtual Mirror | Innovate eyewear shopping experience</title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta
          name="title"
          content="OIC Virtual Mirror | Innovate eyewear shopping experience"
        />
        <meta
          name="description"
          content="OIC Virtual Try-Ons provides both Selfie Try-On/Try-On in 2D and Try-on in 3D/Live-Try-On using an Ultra-realistic rendering engine with face recognition."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta
          property="og:description"
          content="OIC Virtual Try-Ons provides both Selfie Try-On/Try-On in 2D and Try-on in 3D/Live-Try-On using an Ultra-realistic rendering engine with face recognition."
        />
      </Helmet>
      <Layout>
        <div
          className="bannerImage"
          style={{
            backgroundImage: `url("https://oicweb-media.s3.ap-south-1.amazonaws.com/3d-try-on.jpg")`,
          }}
        >
          <img src="" alt="OIC Virtual Mirror Technology" className="altImage" />
          <div className="container">
            <div className="row">
              <div className="col-md-6 bannerTextPadder">
                <h1>
                  INNOVATE A <span>MODERN</span>, PERSONALIZED{" "}
                  <span>EYEWEAR SHOPPING EXPERIENCE</span>
                </h1>
                <a href="https://3d-live.oicapps.com/" target="_blank" className="buttonStyleWhite hvr-forward">
                  Start Free Demo
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60" style={{ background: "#ECEDEF" }}>
          <div className="container" data-aos="fade-right" data-aos-duration="400">
            <div className="row aligner">
              <div className="col-md-2">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/eye-glass.png"
                  className="rotate-left"
                ></img>
              </div>
              <div className="col-md-8 text-center">
                <p
                  style={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontSize: "20px",
                  }}
                >
                  Our platform captures a precise map of each customer’s face
                  and their personal style preferences to help them:
                </p>
                <p style={{ textAlign: "center", lineHeight: "2" }}>
                  Get insights on what works for their face and prescription See
                  their options—on their own face—in vivid realism Narrow the
                  search to the pairs that work for them Gain confidence to buy
                  (and come back to shop again)
                </p>
              </div>
              <div className="col-md-2">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/eye-glass.png"
                  className="rotate-right"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60">
          <div className="container" data-aos="fade-left" data-aos-duration="400">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>
                  <span className="realistic-marker-highlight">Features</span>
                </h2>
              </div>
              <div className="container IconBoxText">
                <div className="row featureglass">
                  <div className="featureglasses"><img src={require("../img/hp/Features_Glass.png")} /></div>
                  <div className="col-md-6 text-center">
                    <div className="transitionRingsL" />
                    <div className="transitionRingsModifiedL" />
                    <div className="featureMarginCorrection IconBox blinkTransition">
                      <img className="transitionMirrorImages"></img>
                      <p className="transitionMirrorText">
                      </p>
                    </div>
                    <br></br>
                    <p className="featureIconDesc featureMarginTextCorrection">
                      Enjoy a real-time glimpse of the facial frames! Allow
                      access to the camera. To take live video with the product.
                    </p>
                  </div>
                  <div className="col-md-6 text-center">
                    <div className="transitionRingsR" />
                    <div className="transitionRingsModifiedR" />
                    <div className="IconBox blinkTransition">
                      <img className="transitionMirrorImages transitionDirection"></img>
                      <p className="transitionMirrorText transitionDirection">
                      </p>
                    </div>
                    <br></br>
                    <p className="featureIconDesc">
                      Helps to figure out the face points to give realistic
                      views of glass on the client. Provides a live shopping
                      experience for the client.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <div className="padding60 blueBackground">
          <div className="container" data-aos="fade-right" data-aos-duration="400">
            <div className="row aligner">
              <div className="col-md-5">
                <h2 className="realistic-marker-highlight">2D Try On</h2>
                <p>
                  With 2D realism, you can attempt on glasses from the comfort
                  of your couch. 2D Try-On gives the 2D image based view of the
                  consumer.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-7">
                {/* <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/2d-try-on-01.png"
                  className="img-responsive" alt="Tryon glasses on the human face"
                ></img> */}

                <div className="tryonContainer">
                  <div className="tryonPreview"><img id="virtualpreview" src={require("../img/virtual-mirror/Eyeglasss1_1.png")} /></div>
                  <div>
                    <div className="tryonArrow"><img src={require("../img/virtual-mirror/Polygon-2.png")} alt="arrow" /></div>
                    <div className="tryonBox">
                      <div onClick={() => moveCarouselLeft()} >
                        <img width="10" height="18" src={require("../img/virtual-mirror/arrow-back.svg")} id="arrow-back" />
                      </div>

                      <div> 
                        <div className="virtualCarousel">
                          <div><img src={virtual_icon1} alt="img-1"/></div>
                          <div><img src={virtual_icon2} alt="img-2"/></div>
                          <div><img src={virtual_icon3} alt="img-3"/></div>
                          <div><img src={virtual_icon4} alt="img-4"/></div>
                          <div><img src={virtual_icon5} alt="img-5"/></div>
                          <div><img src={virtual_icon4} alt="img-6"/></div>
                          <div><img src={virtual_icon3} alt="img-7"/></div>
                          <div><img src={virtual_icon2} alt="img-8"/></div>
                          <div><img src={virtual_icon1} alt="img-9"/></div>
                        </div>

                      </div>

                      <div onClick={() => moveCarouselRight()}>
                        <img width="10" height="18" src={require("../img/virtual-mirror/arrow-fwd.svg")} id="arrow-fwd" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60 ">
          <div className="container-fluid" data-aos="fade-left" data-aos-duration="400">
            <div className="row aligner">
              <div className="col-md-4 text-center DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/2d-try-on-02.png"
                  className="img-responsive" alt="Compare your products with OIC try on glasses"
                ></img>
              </div>
              <div className="col-md-4 text-center">
                <h2>
                  <span className="realistic-marker-highlight">
                    Compare Products
                  </span>
                </h2>
                <p>
                  The use of the 2D Try On, that allows you to compare with
                  exclusive merchandise and pick out the great for you with our
                  Try On.
                </p>
              </div>
              <div className="col-md-4 text-center DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/2d-try-on-03.png"
                  className="img-responsive" alt="Compare your products with OIC try on glasses"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60 blueBackground">
          <div className="container" data-aos="fade-right" data-aos-duration="400">
            <div className="row aligner">
              <div className="col-md-3 DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/2d-try-on-04.png"
                  className="img-responsive" alt="Video rendering about 2d functions"
                ></img>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-5">
                <h2 className="realistic-marker-highlight">
                  2D Try On Function
                </h2>
                <p>
                  Guide the client's realistic output with a 2D photo with
                  glasses earlier than purchasing. Sharing your favourite seems
                  along with your pals is simple when you attempt on glasses
                  on-line.
                </p>
                <br></br> <br></br>
              </div>
              <div className="col-md-4 mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/2d-try-on-04.png"
                  className="img-responsive" alt="Video rendering about 2d functions"
                ></img>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60" style={{ background: "#ECEDEF" }}>
          <div className="container" data-aos="fade-left" data-aos-duration="400">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10 text-center">
                <h2>
                  <span className="realistic-marker-highlight">3D Try On</span>
                </h2>
                <p>
                  Make a video to try glasses on yourself from all angles. Try
                  numerous frames on your face and compare looks. Discover what
                  glasses will suit you best. OiC Try on glasses online and see
                  how a frame will look in seconds with realistic 3D/180-degree
                  views.
                  <br></br>
                  <br></br>
                  Improve your business’s impact, and reap the rewards of an
                  improved conversion rate and reduced rate of product returns.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="blueBackground">
          <div className="container-fluid" data-aos="fade-right" data-aos-duration="400">
            <div className="row ">
              <div className="col-md-6 aligner">
                <div className="container">
                  <div className="row padding60">
                    <div className="col-md-6"></div>
                    <div className="col-md-6">
                      <h2 className="realistic-marker-highlight">3D Library</h2>
                      <p>
                        The OIC 3D Library will have top branded brands 3D
                        product views for consumers.
                      </p>
                      <a href="#" className="buttonStyle hvr-forward">
                        Explore Now
                      </a>
                      <br></br> <br></br>
                    </div>
                  </div>{" "}
                </div>
              </div>
              <div className="col-md-6 aligner text-right imgWidth">
                <img src="https://oicweb-media.s3.ap-south-1.amazonaws.com/wholesaler-glass.png" 
                  alt="Inventory library with multiple glasses"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60 " style={{ background: "#ECEDEF" }}>
          <div className="container" data-aos="fade-left" data-aos-duration="400">
            <div className="row ">
              <div className="col-md-12  text-center">
                <h2>
                  <span className="realistic-marker-highlight">
                    Try-On View
                  </span>
                </h2>
                <p>
                  Allows customers to try on glasses in 3D — Before you buy, try
                  on the glasses in person.
                </p>
              </div>
              <div className="col-md-12 text-center">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/3d-try-on-01.png"
                  className="img-responsive" alt="Facial recognition on Tryon platform"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60 blueBackground">
          <div className="container" data-aos="fade-right" data-aos-duration="400">
            <div className="row aligner">
              <div className="col-md-7 DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/3d-try-on-02.png"
                  className="img-responsive" alt="3D Glasses available in Laptop"
                ></img>
              </div>
              <div className="col-md-5">
                <h2 className="realistic-marker-highlight">My 3D Inventory</h2>
                <p>
                  The distributor will add items from the 3D Library to their
                  inventory, which will appear in My 3D.
                </p>
                <br></br> <br></br>
              </div>
              <div className="col-md-7 mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/3d-try-on-02.png"
                  className="img-responsive" alt="3D Glasses available in Laptop"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <Testimonail />
      </Layout>
    </div>
  );
}
